/*start Hearder css*/
.wrap-logo {
    max-width: 130px;
    width: 130px;
    padding-left: 23px;
}

.header-logo {
    width: 70px;
}

.setupHead {
    font-weight: 500;
    padding-top: 9px;
    margin-bottom: 0px;
    font-size: 1.5rem;
}

.setupHead-sub {
    font-weight: 500;
    padding-top: 8px;
    margin-bottom: 5px;
    font-size: 1.2rem;
}

.fixed-header {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.mb-arrow {
    width: 20%;
    text-align: center;
}

@media(max-width:990px) {
    .setupHead {
        font-weight: 500;
        padding-top: 9px;
        margin-bottom: 0px;
        font-size: 1.3rem;
    }

    .mb-arrow .btn-arw {
        border: 0px;
        background-color: transparent;
        padding: 2px;
        margin: 0px 5px;
    }

    .mb-arrow i {
        color: #52B6ED;
        font-weight: 600;
        font-size: 28px;
    }
}

@media(max-width:767px) {
    .setupHead {
        font-weight: 500;
        padding-top: 0px;
        margin-bottom: 0px;
        font-size: 1.2rem;
    }

    .mb-arrow .btn-arw {
        border: 0px;
        background-color: transparent;
        padding: 1px;
        margin: 0px 3px;
    }

    .mb-arrow i {
        color: #52B6ED;
        font-weight: 600;
        font-size: 19px;
    }
}

/*End Header css*/
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

#sidebar-wrapper {
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.side-menu {
    position: fixed;
    background-color: #fff;
    width: 220px;
    height: 100%;
    padding: 0px 0px;
    transition: width .2s ease-in;
    top: 0px;
    z-index: 9;
    transform: translateY(75px);
}

.side-menu.inactive {
    width: 80px;
}

.toggle-menu-btn {
    color: #E2E0ED;
    font-size: 20px;
    position: absolute;
    top: -53px;
    left: 200px;
    cursor: pointer;
    z-index: 999999999999;
}

.selection-cell-header input[type=checkbox],
.selection-cell input[type=checkbox],
.select-cell input[type=checkbox] {
    width: 17px !important;
    height: 17px !important;
}

.select-cell label {
    margin-bottom: 0px !important;
}

.select-cell,
.selection-cell {
    line-height: 2px;
}

.selection-cell-header {
    line-height: 4px;
    vertical-align: middle !important;
}

.side-menu .toggle-menu-btn i {
    color: #707070;
}

/* .side-menu.inactive .toggle-menu-btn{
    transform: translateX(60px);
} */
.side-menu ul {
    list-style-type: none;
}

.side-menu .main-menu .menu-item {
    text-decoration: none;
    font-size: 15px;
    display: block;
    cursor: pointer;
    padding: 3px 20px;
    color: #707070;
}

.side-menu .main-menu ul li.first .menu-item.active,
.side-menu .main-menu ul li.first .menu-item:hover {
    background-color: #ffffff;
    color: #322481 !important;
    transition: all ease 0.3s;
}

.side-menu .main-menu ul li .menu-item.active {
    background-color: #E2E0ED;
    color: #322481 !important;
    transition: all ease 0.3s;
}

.side-menu .main-menu ul li .menu-item:hover {
    background-color: #cac6dd;
    color: #322481 !important;
    transition: all ease 0.3s;
}

.side-menu .main-menu .menu-item:hover span,
.side-menu .main-menu .menu-item.active span,
.side-menu .main-menu .menu-item.active .menu-icon i,
.side-menu .main-menu .menu-item:hover .menu-icon i {
    color: #322481 !important;
    transition: all ease 0.3s;
}

.side-menu .main-menu .menu-item span {
    position: absolute;
    display: inline-block;
    line-height: 40px;
    opacity: 1;
    transition: opacity .2s ease-in;
}

.side-menu.inactive .main-menu .menu-item span {
    opacity: 0;
    width: 0px;
    height: 0px;
    overflow: hidden;
}

.side-menu .main-menu .sub-menu {
    color: #333;
    margin-left: 20px;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 30px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-in;
}

.side-menu .main-menu .sub-menu.active {
    max-height: 200px;
}

.side-menu .main-menu .sub-menu a {
    display: block;
    margin: 5px 0px;

}

.side-menu .main-menu .menu-item .menu-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
}

.rightContainer {
    margin-left: 220px;
    padding: 0px;
    transition: margin-left .2s ease-in;
    background-color: #f4f6f9;
    /* height: calc(100vh - 78px); */
    box-sizing: border-box;
    z-index: -1;
    border-radius: 44px 0px 0px 0px;
    border-top: 1px solid #E2E0ED;
    border-left: 1px solid #E2E0ED;
}

.dash-header {
    width: 100%;
    background-color: #fff;
}

.margin-right-custom {
    margin-right: 1rem;
}

.rightContainer.inactive {
    margin-left: 80px;
}

.avatar {
    width: 33px;
    height: 33px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 25px;
    z-index: 1;
    margin-right: 10px;
}

.avatar img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.avatar .profile-icon {
    font-size: 19px;
    background-color: #322481;
    color: #fff;
    max-width: 100%;
    height: 100%;
    padding: 6px 12px 6px 12px;
    line-height: 22px;
}

.profile-id {
    background-color: #ECECEC;
    color: #322481;
    font-size: 14px;
    padding: 8px 9px 8px 16px;
    border-radius: 4px 14px 14px 4px;
    transform: translateX(-19px);
    z-index: 0;
}

.react-bootstrap-table thead tr th,
.react-bootstrap-table tbody tr td {
    font-size: 13px;
    white-space: nowrap;
}

.wrapperData-table tbody tr {
    height: 70px;
    vertical-align: middle;
}

.react-bootstrap-table tbody tr td {
    padding: 10px 8px;
}

.wrapperInstance-table tbody tr td {
    font-weight: 600;
    background-color: #EAF3FE;
    border-bottom: 0px;
    white-space: nowrap;
}

.wrapperInstance-table tbody tr {
    vertical-align: top;
}

.wrapperInstance-table tbody tr td:nth-child(3),
.wrapperInstance-table tbody tr td:nth-child(5) {
    color: #32B900 !important;
}

.wrapperHistory-table thead tr th {
    font: 14px !important;
}

.wrapperHistory-table tbody tr td {
    font-size: 14px !important;
}

.wrapperHistory-table tbody tr {
    vertical-align: top;
}

.wrapperHistory-table tbody tr td:first-child {
    border-left: 1px solid #D5D5D5;
}

.wrapperHistory-table tbody tr td:last-child {
    border-right: 1px solid #D5D5D5;
}

.wrapperHistory-table tbody tr td:nth-child(3) {
    text-align: center;
}

.wrapperHistory-table tbody tr td:nth-child(3),
.wrapperHistory-table tbody tr td:nth-child(7) {
    color: #32B900 !important;
}

.wrapperData-table tbody tr td:first-child,
.wrapperDataInstance-table tbody tr td:first-child,
.wrapperListInstance-table tbody tr td:first-child {
    cursor: pointer;
    color: #1B66C9;
}

.wrapperDataInstance-table tbody tr td {
    vertical-align: middle;
}

.react-bootstrap-table th {
    position: relative;
}

.dropdownTable-Dash {
    width: 142px;
}

table {
    margin-bottom: 0px !important;
}

.dropdownTable-Dash::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 72px;
    background: url(src/assets/images/dropdown-icon.svg);
    background-repeat: no-repeat;
    width: 17px;
    height: 11px;
    background-size: 13px;
    line-height: 5px;
}

.wrapperListInstance-table tbody tr td {
    position: relative;
}

.icon-header {
    font-size: 1.6rem;
    cursor: pointer;
    transition: all ease .3s;
    color: #c4cad7;
}

.icon-header:hover {
    transition: all ease .3s;
    color: #322481;
}

.search-wrap {
    position: relative !important;
}

.search-wrap .search-input {
    height: 37px;
    border-radius: 17px;
    border: 1px solid #D5D5D5;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 20px;
    font-size: 13px;
}

.search-wrap .search-input:hover,
.search-wrap .search-input:focus {
    outline: none;
}

.search-btn {
    border: none !important;
    position: absolute !important;
    right: 12px;
    background-color: transparent;
    top: 6px;
    font-size: 17px;
    color: #707070;
}

.header-droparw {
    background-color: transparent !important;
    font-size: 20px !important;
    border: none !important;
    line-height: 3px !important;
    padding-right: 0px;
}

.header-droparw:hover,
.header-droparw:active,
.header-droparw:focus {
    background-color: transparent !important;
    font-size: 20px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.header-droparw i:hover,
.header-droparw i:active,
.header-droparw i:focus {
    color: #322481 !important;
}

.header-droparw.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border: none !important;
}

.header-droparw .bi-chevron-down {
    font-size: 12px;
}

/**dashboard content****/
.wrapperData-table thead tr th:last-child,
.wrapperInstance-table thead tr th:last-child,
.wrapperHistory-table thead tr th:last-child {
    border-radius: 0px 9px 0px 0px;
}

.wrapperData-table thead tr th:first-child,
.wrapperInstance-table thead tr th:first-child,
.wrapperHistory-table thead tr th:first-child {
    border-radius: 9px 0px 0px 0px;
}

.wrapperInstance-table thead tr th {
    vertical-align: top;
}

.wrapperInstance-table tbody tr td:last-child,
.wrapperHistory-table tbody tr td:last-child {
    border-radius: 0px 0px 9px 0px;
}

.wrapperInstance-table tbody tr td:first-child,
.wrapperHistory-table tbody tr td:first-child {
    border-radius: 0px 0px 0px 9px;
}

.wrapperDataInstance-table {
    border: 1px solid #ddd;
    border-radius: 12px;
}

.wrapperDataInstance-table thead tr th:last-child {
    border-radius: 0px 9px 0px 0px;
}

.wrapperDataInstance-table thead tr th:first-child {
    border-radius: 9px 0px 0px 0px;
}

.wrapperListInstance-table thead tr th:last-child {
    border-radius: 0px 9px 0px 0px;
}

.wrapperListInstance-table thead tr th:first-child {
    border-radius: 9px 0px 0px 0px;
}

.wrapperListInstance-table .react-bootstrap-table-editing-cell input,
.wrapperListInstance-table .react-bootstrap-table-editing-cell select {
    height: 28px !important;
    outline: none !important;
    box-shadow: none !important;
    padding-top: 0px !important;
    font-size: 13px !important;
    width: 100px !important;
    overflow: hidden !important;
}

.wrapperData-table thead tr th {
    border-bottom: none;
}

.heading-dashboard {
    background-color: #322481;
    color: #fff;
    padding: 8px 17px 8px 20px;
    font-size: 19px;
    text-transform: uppercase;
    line-height: 30px;
}

.wrap-dash1 {
    background-color: #fff;
    border: 1px solid #D5D5D5;
    border-radius: 20px;
    padding: 10px;
    transition: all ease 0.4s;
}

.wrap-dash1:hover {
    box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.4);
    transition: all ease 0.4s;
}

.wrap-dash1 .wrap-img {
    padding: 6px 0px;
}

.wrap-dash1 .wrap-h {
    height: 220px;
}

.inner-wrap-ylw {
    background-image: linear-gradient(#A7DB1E, #4C6E00);
    padding: 15px;
    border-radius: 14px;
    margin-top: 8px;
    height: 113px;
}

.inner-wrap-blu {
    background-image: linear-gradient(#97DAFD, #53B6ED);
    padding: 15px;
    border-radius: 14px;
    margin-top: 8px;
    height: 113px;
}

.line-h {
    line-height: 9px;
}

.price {
    color: #fff;
    font-size: 23px;
}

.inc-price {
    font-size: 17px;
    color: #fff;
}

.graph-i {
    font-size: 35px;
    transform: rotate3d(120);
}

.wrap-dash1 .heading {
    text-align: center;
    font-size: 1.1rem;
    color: #707070;
}

.wrap-dash1 .heading-left {
    font-size: 1.1rem;
    color: #707070;
}

.wrap-dash1 .heading-left-activity {
    font-size: 1.1rem;
    color: #333;
}

.i-history {
    font-size: 21px;
    position: relative;
    top: 2px;
    margin-left: 5px;
}

.wrap-dash1 .inner-wrap {
    border: 1px solid #D5D5D5;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 15px;
}

.border-btm {
    background-color: #D5D5D5;
    width: 100%;
    height: 1px;
    margin: 8.3px 0px;

}

.wrap-dash1 .inner-wrap .sub {
    font-size: 1rem;
    font-weight: 500;
}

.c-green {
    color: #62BB35
}

.c-red {
    color: #FF0000
}

.margin-top-dash {
    margin-top: 75px;
}

.header-fix {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2;
    background-color: #fff;
    padding: 5px 5px;
}

.drop-alignment {
    right: 20px !important;
    left: auto !important;
    top: -8px !important;
    z-index: 9999999 !important;
    padding: 15px 15px;
    width: 328px;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    font-size: 13px;
}

.aws-accTxt {
    font-size: 15px;
}

.acct-id {
    color: #959595;
}

.drop-alignmentNoti {
    right: 9px !important;
    left: auto !important;
    top: -2px !important;
    padding: 0px !important;
    width: 215px;
}

.drop-alignmentNoti .heading {
    font-size: 1.2rem !important;
    font-weight: 500;
    color: #838383;
    padding: 7px 12px 6px;
}

.drop-alignmentNoti .drop-inner {
    max-height: 200px;
    height: 200px;
    overflow-y: auto;
    padding: 10px 0px 0px 15px !important;
}

.drop-alignmentNoti .drop-inner a {
    color: #838383 !important;
}

.drop-alignmentNoti .drop-inner a:hover {
    color: #322481 !important;
}

.drop-alignmentNoti .drop-footer {
    background-color: #E2E0ED;
    padding: 5px 10px;
    font-size: 1rem;
}

.drop-alignmentNoti .drop-footer a {
    color: #707070 !important;
}

.drop-alignment a {
    color: #322481 !important;
    transition: all ease 0.3s;
    padding: 4px 20px 4px 12px;
}

.isACCActive {
    background-color: #EFEFEF;
}

.isACCActive i {
    visibility: visible;
}

.drop-alignment a:hover {
    color: #5d51a1 !important;
    transition: all ease 0.3s;
}

.drop-alignment a.active,
.drop-alignment a.active i {
    color: #322481 !important;
    transition: all ease 0.3s;
}

.noti-wrap {
    position: relative;
}

.noti-wrap .notification {
    position: absolute;
    left: 24px;
    height: 5px;
    width: 5px;
    border-radius: 16px;
    background-color: #FF0000;
    border: 2px solid #ccc;
    padding: 4px;
    top: -7px;
}

.notification-dropdown .drop-alignmentNoti::-webkit-scrollbar {
    width: 8px;
}

.notification-dropdown .drop-alignmentNoti::-webkit-scrollbar-track {
    background: transparent;
}

.notification-dropdown .drop-alignmentNoti::-webkit-scrollbar-thumb {
    background: #888;
}

.notification-dropdown .drop-alignmentNoti::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.notification-dropdown .drop-alignmentNoti {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #EFEFEF !important;
}

.drop-alignmentNoti .drop-inner::-webkit-scrollbar-track {
    width: 6px;
    background-color: #F5F5F5 !important;
    border: 2px solid #F5F5F5 !important;
}

.drop-alignmentNoti .drop-inner::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5 !important;
    border: 2px solid #F5F5F5 !important;
}

.btn-light {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}

.drop-alignmentNoti .drop-inner::-webkit-scrollbar-thumb {
    background-color: #f4f6f9 !important;
    border: 1px solid #F5F5F5;
}

.doc-subheading {
    color: #707070;
    font-weight: 400;
}

.pagination {
    display: inline-flex !important;
    margin-left: auto !important;
}

@media(max-width:768px) {
    .heading-dashboard {
        background-color: #53B6ED;
        color: #fff;
        padding: 8px 12px 8px 8px;
    }

    .rightContainer.inactive {
        margin-left: 0px;
    }

    .side-menu.inactive {
        width: 220px;
        z-index: 999;
    }

    .rightContainer.inactive::before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9 !important;
    }

    .rightContainer {
        margin-left: 0px;
        padding: 0px;
        transition: margin-left .2s ease-in;
        background-color: #f2f2f2;
        /* height: calc(100vh - 78px); */
        box-sizing: border-box;
        z-index: -1;
    }

    .side-menu {
        position: fixed;
        background-color: #fff;
        width: 0px;
        height: 100%;
        padding: 0px 0px;
        transition: width .2s ease-in;
        box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
        top: 0px;
        z-index: 9;
        transform: translateY(75px);
    }

    .side-menu .main-menu {
        opacity: 0;
    }

    .side-menu.inactive .main-menu .menu-item span {
        opacity: 1;
        width: auto;
        height: auto;
        overflow: initial;
    }

    .side-menu.inactive .main-menu {
        opacity: 1;
    }
}

@media(max-width:567px) {
    .toggle-menu-btn {
        color: #f2f2f2;
        font-size: 20px;
        position: absolute;
        top: -53px;
        left: 99px;
        cursor: pointer;
        z-index: 999999999999;
    }
}

@keyframes highlightFade {
    50% {
        background-color: #bbdefb;
        opacity: 1;
    }
}