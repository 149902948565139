* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

body,
html {
    overflow-x: hidden;
    font-family: 'AdobeCleanRegular' !important;
    letter-spacing: 1px;
    color: #3a3a3a !important;
}

@font-face {
    font-family: 'AdobeCleanRegular';
    src: url('src/assets/fonts/AdobeCleanRegular.eot');
    src: url('src/assets/fonts/AdobeCleanRegular.eot?#iefix') format('embedded-opentype'),
        url('src/assets/fonts/AdobeCleanRegular.woff2') format('woff2'),
        url('src/assets/fonts/AdobeCleanRegular.woff') format('woff'),
        url('src/assets/fonts/AdobeCleanRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('src/assets/fonts/OpenSans-Regular.eot');
    src: url('src/assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('src/assets/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('src/assets/fonts/OpenSans-Regular.woff') format('woff'),
        url('src/assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

.font-open {
    font-family: 'OpenSans-Regular' !important;
    letter-spacing: 0px;
}

.btn-primary {
    background-color: #1C75CF !important;
    border-color: #1C75CF !important;
    transition: all ease 0.4s;
}

.btn-primary:hover {
    background-color: #1f5f9f !important;
    border-color: #1f5f9f !important;
    transition: all ease 0.4s;
}

.logout-button-margin {
    margin-right: 10px;
}

ul {
    padding-left: 0px !important;
}

a {
    text-decoration: none !important;
}

.underline {
    text-decoration: underline !important;
}

.heading-thank {
    font-size: 51px;
}

.desh-height {
    height: 100%;
}

.h100-vh {
    height: 100vh;
}

.pageWrap {
    background-image: url(src/assets/images/background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: bottom; 
}

/*start Login*/
.loginWrap {
    background-image: url(src/assets/images/background.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: bottom;
}

.loginWrap .mr-login {
    margin-right: 98px;
}

.login-heading-sub {
    font-size: 20px;
}

.loginBox {
    border: 1px solid #BFBFBF;
    border-radius: 7px;
    padding: 24px 40px 30px 40px;
    background-color: #F4F4F4;
    transition: all ease 0.4s;
}

.loginBox:hover {
    box-shadow: 1px 0px 10px rgba(36, 42, 129, 0.3);
    background-color: #Fff;
}

.loginBox h1 {
    color: #242A81;
}

.loginBox h1 {
    font-size: 35px;
    margin-bottom: 8px;
}

.signupBox {
    border: 1px solid #BFBFBF;
    border-radius: 7px;
    padding: 28px 51px 27px 51px;
    background-color: #F4F4F4;
    transition: all ease 0.4s;
}

.subtext {
    letter-spacing: 0px;
    line-height: 18px;
}

.signupBox:hover {
    box-shadow: 1px 0px 10px rgba(36, 42, 129, 0.3);
    background-color: #Fff;
}

.signupBox h1 {
    color: #242A81;
}

.signupBox h1 {
    font-size: 35px;
    margin-bottom: 8px;
}

.logo-slide-up {
    animation: slideUp 0.5s ease forwards;
}

.text-fade-in {
    animation: fadeIn 0.5s ease forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-50px);
        /* Adjust the value as needed */
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.form-style {
    border: none !important;
    border-bottom: 2px solid #242A81 !important;
    border-radius: 0px !important;
    padding-left: 0px !important;
    color: #242a81 !important;
    background-color: transparent !important;
}

.form-floating>label {
    padding-left: 0px !important;
    color: #ABABAB;
    font-size: 14px;
    left: -3px;
}

.form-floating>.form-control:focus~label::after {
    background-color: transparent !important;
}

.form-style:focus {
    box-shadow: none !important;
    border-bottom: 2px solid #5d64c4 !important;
}

.link-script {
    color: #000;
    transition: all ease 0.3s;
}

.link-script:hover {
    color: #322481;
    transition: all ease 0.3s;
    text-shadow: 1px 2px 8px rgba(0, 0, 0, 0.3);
}

.form-check-input-style {
    color: #4C4C4C !important;
    font-size: 14px !important;
    margin-bottom: 0px !important;
}

.wel-link {
    color: #1B66C9;
    transition: all ease 0.4s;
    border-bottom: 1px solid #1B66C9;
}

.wel-link:hover {
    color: #1f5f9f;
    transition: all ease 0.4s;
    border-bottom: 1px solid #1f5f9f;
    text-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);
}

.link-color {
    color: #1B66C9;
    transition: all ease 0.4s;
}

.link-color:hover {
    color: #1f5f9f;
    transition: all ease 0.4s;
    text-shadow: 1px 0px 10px rgba(0, 0, 0, 0.3);
}

.login-style {
    font-size: 20px !important;
}

.passwrap {
    position: relative;
}

.passwrap img {
    position: absolute;
    top: 50%;
    right: 15px; /* Adjust as needed */
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px; /* or adjust for your design */
    height: auto;
}

.cursor {
    cursor: pointer;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
    background-color: transparent !important;
}

/*end Login*/
/*start Welcome*/
.welcomeWrap {
    background-image: url(src/assets/images/backgroundcolor.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    background-position: center;
}

.wrap-graph .graph {
    background-color: #fff;
    transition: all ease 0.3s;
    border: 1px solid #D5D5D5;
    border-radius: 12px;
    padding: 15px 10px 10px 15px;
}

.wrap-graph .graph:hover {
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.2);
    transition: all ease 0.3s;
}

/*end Welcome*/
/*start Add Account*/
.text-wrap-uptime {
    padding-left: 20%
}

.text-wrap-uptime1 {
    padding-left: 19%
}

.text-black {
    color: #000;
}

.text-blakish {
    color: #3A3A3A;
}

.f27 {
    font-size: 27px;
}

.f21 {
    font-size: 21px;
}

.f18 {
    font-size: 18px;
}

.f14 {
    font-size: 14px;
}

.f13 {
    font-size: 13px;
}

.f15 {
    font-size: 15px;
}

.border-purple {
    border: 1px solid #E2E0ED
}

.w24 {
    width: 23px;
}

.rounded-cust {
    border-radius: 12px;
}

.bg-white {
    background-color: #fff;
}

.font-weight-bold {
    font-weight: 600;
}

.border-t-1 {
    border-top: 1px solid #ECF4FF;
}

.h-scroller::-webkit-scrollbar-track {
    width: 7px;
    background-color: #F5F5F5 !important;
    border: 2px solid #F5F5F5 !important;
}

.h-scroller::-webkit-scrollbar {
    width: 7px;
    background-color: #666 !important;
    border: 2px solid #F5F5F5 !important;
}

.react-bootstrap-table thead tr th {
    background-color: #EFEFEF !important;
}

.react-bootstrap-table thead tr th::after {
    content: '';
    position: absolute;
    left: -4px;
    height: 20px;
    width: 1px;
    background-color: #ACACAC;
    top: 10px;
}

.h-scroller::-webkit-scrollbar-thumb {
    background-color: #cccccc !important;
    border: 1px solid #F5F5F5;
}

.profile-dropdown-icon {
    width: 41px;
    height: 41px;
    background-color: #322481;
    border-radius: 25px;
    color: #fff;
    padding: 4px 9px 4px 11px;
    font-size: 22px;
}

.profile-dropdown-icon .acct-id {
    color: #959595;
    font-size: 15px;
}

.react-bootstrap-table th .order {
    display: inline-block;
}

.react-bootstrap-table th .order>.dropdown>.caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
}

.progress-cloud {
    height: 21px !important;
}

.react-bootstrap-table table {
    table-layout: auto !important;
    display: table;
    width: 100%;
}

.react-bootstrap-table .btn-action {
    border-radius: 30px;
    font-size: 13px;
    padding: 3px 12px;
    background-color: #322481 !important;
    border-color: #322481 !important;
    transition: all ease 0.3s;
}

.react-bootstrap-table .btn-action:hover {
    background-color: #6957cb !important;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}

.react-bootstrap-table .btn-connected {
    border-radius: 30px;
    font-size: 13px;
    padding: 3px 12px;
    background-color: #62BB35 !important;
    border-color: #62BB35 !important;
    transition: all ease 0.3s;
}

.react-bootstrap-table .btn-connected:hover {
    background-color: #88e35a !important;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);
}

.progressbar-sm {
    height: 12px !important;
    border: 1px solid #afd4f9;
}

small {
    font-size: 10px !important;
}

.progressbar-sm.primary .progress-bar {
    background-color: #ABCEF2 !important;
    border-radius: 12px;
}

.progressbar-sm.danger .progress-bar {
    background-color: #FF7F0F !important;
    border-radius: 12px;
}

.form-control {
    background-color: transparent !important;
}

.width-logo-sidebar {
    width: 526px;
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    flex-direction: column;
}

.center-logo {
    width: 150px;
    animation: fadeIn 1s ease-out forwards;
}

.loading-message {
    margin-top: 20px;
    font-size: 1.2rem;
    color: #333;
    animation: fadeIn 1s ease-out 0.5s forwards;
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.msg-instance {
    background-color: #ECF4FF;
    border: 1px solid #ABCEF2;
    color: #6D6D6D;
    padding: 6px 15px;
    font-size: 13px;
}

.wrap-details-acct {
    box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.2);
    height: 100%;
    padding: 20px;
    transition: all ease 0.3s;
}

.wrap-details-acct:hover {
    box-shadow: 1px 3px 11px rgba(0, 0, 0, 0.3);
    transition: all ease 0.3s;
}

.wrap-details-acct .text {
    font-size: 13px;
    color: #6D6D6D;
    margin-bottom: 13px;
    font-weight: 600;
}

.wrap-details-acct .prize {
    font-weight: 600;
    font-size: 27px;
    color: #000;
}

.wrap-details-acct .innerWrap {
    border-left: 1px solid #4C97E2;
    padding-left: 20px;
}

.btn-outline-primary {
    background-color: #ECF4FF !important;
    color: #1B66C9 !important;
    transition: all ease 0.3s !important;
    border-color: #ABCEF2 !important;
}

.btn-outline-primary:hover {
    background-color: #fff !important;
    border: 1px solid #1B66C9 !important;
    transition: all ease 0.3s !important;
}

.priority-btn {
    padding: 1px 14px !important;
}

.btn-success {
    background-color: #32B900 !important;
    border-color: #32B900 !important;
    transition: all ease 0.3s;
}

.btn-success:hover {
    background-color: #409521 !important;
    border-color: #409521 !important;
    transition: all ease 0.3s;
}

.bg-filter-wrap {
    background-color: #fff;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    position: absolute;
    z-index: 99;
    top: 0px;
    width: 400px;
}

.bg-filter-wrap .wrap-header {
    background-color: #ECF4FF;
    padding: 8px 10px;
}

.bg-filter-wrap .wrap-header .text {
    color: #1B66C9;
}

.text-right {
    float: right;
}

.bg-filter-wrap ul {
    list-style-type: none;
    padding: 3px 15px 10px 0px !important;
}

.bg-filter-wrap ul li {
    line-height: 33px;
    background-color: #F5F5F5;
    padding-left: 12px;
    transition: all ease 0.3s;
    padding-right: 12px;
    border: 1px solid #F5F5F5;
    cursor: pointer;
    font-weight: bold;
}

.bg-filter-wrap ul li:hover {
    background-color: #fff;
    transition: all ease 0.3s;
    border: 1px solid #f2f2f2;
    white-space: nowrap;
}

.bg-filter-wrap ul li.active {
    background-color: #fff;
}

.bg-filter-wrap ul li.selected {
    background-color: #CBE1F7;
}

.filter-category-list {
    list-style: none;
    padding-left: 0;
}

.filter-category-item {
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.filter-category-item.active {
    background-color: #1976d2;
}

.filter-count {
    float: right;
}

.filter-options-list {
    list-style: none;
    padding-left: 0;
}

.selectFilter-row div:last-child {
    cursor: pointer;
    color: #1B66C9;
}

.selectFilter-row div {
    padding: 10px 9px;
    background-color: #EBEBEB;
    color: #333;
    margin-right: 12px;
    border-radius: 6px;
    font-size: 13px;
    transition: all ease 0.3s;
    height: 32px;
}

.selectFilter-row div:hover {
    transition: all ease 0.3s;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3)
}

.selectFilter-row div i {
    font-size: 17px;
    cursor: pointer;
}

.wrap-daywise ul {
    border: 1px solid #707070;
    display: inline-flex;
    justify-content: end;
    list-style-type: none;
    font-size: 15px;
}

.wrap-daywise {
    text-align: right;
    font-size: 15px;
    margin: 14px 0px 13px;
}

.wrap-daywise ul li {
    display: flex;
    cursor: pointer;
    padding: 2px 4px;
}

.wrap-daywise ul li:last-child {
    border-left: 1px solid #707070;
}

.wrap-daywise ul li.active,
.wrap-daywise ul li:hover {
    font-weight: 600;
    background-color: #E0D8F3;
}

/*end account id*/
/*media query*/
.activity .react-bootstrap-table {
    display: block !important;
    width: 100% !important;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto !important;
    max-height: auto !important;
}

.modal-title {
    color: #000 !important
}

@media only screen and (min-width:1920px) {

    /* .wrapperData-table thead tr th, .wrapperData-table thead tr th:nth-child(7), .wrapperData-table thead tr th:nth-child(5), .wrapperDataInstance-table  thead tr th:nth-child(5){
       min-width:127px !important;}  */
    .loginBox {
        padding: 24px 30px 30px !important;
    }

    .loginWrap .mr-login {
        margin-right: 45%;
    }

    .text-wrap-uptime {
        padding-left: 14% !important;
    }

    .text-wrap-uptime1 {
        padding-left: 12.5% !important;
    }
}

@media only screen and (max-width :1500px) {

    .wrapperData-table thead tr th:nth-child(3),
    .wrapperData-table thead tr th:nth-child(7),
    .wrapperData-table thead tr th:nth-child(5),
    .wrapperDataInstance-table thead tr th:nth-child(5) {
        width: 127px;
    }

    .text-wrap-uptime {
        padding-left: 20.3%
    }

    .text-wrap-uptime1 {
        padding-left: 18%
    }
}

@media only screen and (max-width:1440px) {
    .loginWrap .mr-login {
        margin-right: 72px;
    }
}

@media only screen and (max-width:1380px) {
    .loginWrap .mr-login {
        margin-right: 64px !important;
    }
}

@media only screen and (max-width:1280px) {
    .loginWrap .mr-login {
        margin-right: 98px;
    }
}

@media only screen and (min-width:1181px) {
    .border-rgt {
        border-right: 1px solid #e2e0ed;
    }
}

@media(min-width:1024px) {
    .h-scroller {
        height: calc(100vh - 75px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media only screen and (max-width:1180px) {
    .text-wrap-uptime {
        padding-left: 26%;
    }

    .text-wrap-uptime1 {
        padding-left: 26%;
    }

    .signupBox {
        padding: 28px 33px 27px 33px;
    }
}

@media only screen and (max-width:1024px) {
    .loginWrap .mr-login {
        margin-right: 0% !important;
    }
}

@media only screen and (max-width:920px) {
    .welcomeWrap {
        background-size: 107%;
    }

    .text-wrap-uptime {
        padding-left: 17%;
    }

    .text-wrap-uptime1 {
        padding-left: 17%;
    }

    .signupBox h1 {
        font-size: 30px !important;
    }

    .loginWrap .mr-login {
        margin-right: 11%;
    }
}

@media only screen and (max-width:820px) {
    .loginWrap .mr-login {
        margin-right: 8px !important;
    }
}

@media only screen and (max-width:768px) {
    .loginWrap .mr-login {
        margin-right: 0px !important;
    }

    .mb-p-logo {
        padding: 40px 40px 40px;
    }

    .mb-p-logo img {
        padding: 0px 40px;
    }

    .loginBox {
        padding: 20px 20px 20px 20px;
    }

    .signupBox {
        padding: 20px 20px 20px 20px;
    }
}

@media only screen and (max-width:767px) {
    .loginWrap .mr-login {
        margin-right: auto;
    }

    .welcomeWrap {
        background-image: url(src/assets/images/backgroundMobile.svg);
    }

    .desh-height {
        height: auto !important;
    }
}

@media only screen and (max-width:500px) {
    .mb-p-logo img {
        padding: 0px 40px;
        width: 60%;
    }
}

@media only screen and (max-width:320px) {
    .login-heading-sub {
        font-size: 14px;
    }

    .mb-p-logo {
        padding: 25px 40px 20px;
    }
}