.txt-default{color: #707070;font-size: 14px;}
pre{
    display: inline!important;
    width: 20px;
    font-size: 18px;
    color: #707070;
}
.wrap-color{
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color1{
    background-color: #00C2FA;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color2{
    background-color: #007CBB;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color3{
    background-color: #D5D5D5;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color4{
    background-color: #32B900;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color5{
    background-color: #FF9A3A;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color6{
    background-color: #FFE050;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}
.wrap-color7{
    background-color: #FA5951;
    width: 16px;
    height: 16px;
    margin: 0px 9px;
}

